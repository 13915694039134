import * as React from 'react'
import { Helmet } from 'react-helmet'

export const AmpInstagram = (props) => {
  const { className } = props
  return (
    <React.Fragment>
      <Helmet key="helmet">
        <script
          async
          custom-element="amp-instagram"
          src="https://cdn.ampproject.org/v0/amp-instagram-0.1.js"
        />
      </Helmet>
      <amp-instagram className={className} {...props}></amp-instagram>
    </React.Fragment>
  )
}
