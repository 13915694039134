import * as React from 'react'
import { Helmet } from 'react-helmet'

export const AmpPinterest = (props) => {
  const { className } = props
  return (
    <React.Fragment>
      <Helmet key="helmet">
        <script
          async
          custom-element="amp-pinterest"
          src="https://cdn.ampproject.org/v0/amp-pinterest-0.1.js"
        ></script>
      </Helmet>
      <amp-pinterest className={className} {...props}></amp-pinterest>
    </React.Fragment>
  )
}
