import * as React from 'react'
import { useRouter } from 'next/router'

import { RecommendationSourceType } from '@thg-commerce/enterprise-components/Qubit'
import { QubitBasketRecommendations } from '@thg-commerce/enterprise-components/Qubit/QubitBasketRecommendations'
import { BasketProduct } from '@thg-commerce/enterprise-components/Qubit/QubitBasketRecommendations/QubitBasketRecommendations'
import { parseQubitPreviewParams } from '@thg-commerce/enterprise-components/Qubit/utils'
import {
  useEnterpriseContext,
  useSessionSettings,
  useSiteConfig,
  useSiteDefinition,
} from '@thg-commerce/enterprise-core'
import { RecommendationTrackingContext } from '@thg-commerce/enterprise-metrics/src/backend_event/types/tracking'
import { BasketData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/Basket'
import { Feature } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { ProductRecommendations } from '@thg-commerce/enterprise-widget-product-recommendations/src/ProductRecommendations'
import { GridItem } from '@thg-commerce/gravity-system'
import { mq, spacing, styled, Text } from '@thg-commerce/gravity-theme'

const RecommendationsGridItem = styled(GridItem)`
  margin: 0 ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: 0 ${spacing(4)};
  }
`

const RecommendationsTitle = styled.h2`
  ${Text('large1', 'alternate')};
  margin-bottom: ${spacing(3)};
  text-align: center;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-bottom: ${spacing(4)};
  }
`

export const BasketRecommendationsRenderer = ({
  basket,
  i18nText,
}: {
  basket: BasketData
  i18nText: {
    recommendationsTitle: string
    rrpText?: string
  }
}) => {
  const sessionSettings = useSessionSettings()
  const {
    qubit,
    enableVipPrice,
    productDetailKeyAdditionList,
  } = useSiteConfig()
  const { domain } = useSiteDefinition()
  const {
    horizonFeatures,
    appConfig: {
      contentKeys: {
        productDescriptionKeys = {
          items: [],
          details: [],
          video: [],
          download: [],
        },
      } = {},
    },
  } = useEnterpriseContext()
  const router = useRouter()
  const qubitPreviewParams = parseQubitPreviewParams(
    `https://${domain}${router.asPath}`,
  )

  if (!basket || !basket?.items) {
    return null
  }

  if (
    qubit?.enabled &&
    typeof qubit.basketPageRecommendationsPlacementId === 'string'
  ) {
    return (
      <QubitBasketRecommendations
        placementId={qubit.basketPageRecommendationsPlacementId}
        qubitCallbackURL={qubit.callbackURL}
        mode={qubitPreviewParams?.mode ?? qubit.mode}
        previewOptions={{
          campaignId: qubitPreviewParams.campaignId,
          experienceId: qubitPreviewParams.experienceId,
          group: qubitPreviewParams.group,
        }}
        title={i18nText.recommendationsTitle}
        source={{
          type: RecommendationSourceType.BASKET_PAGE,
          currency: sessionSettings.currency,
          shippingDestination: sessionSettings.shippingDestination,
          id: basket.id,
          vipPriceEnabled:
            enableVipPrice &&
            (horizonFeatures || []).includes(Feature.VipPricingEnabled),
          clickAndCollectEnabled: (horizonFeatures || []).includes(
            Feature.ClickAndCollect,
          ),
          productContentKeys: [
            ...(productDescriptionKeys?.items || []),
            ...(productDescriptionKeys?.details || []),
            ...(productDescriptionKeys?.video || []),
            ...(productDetailKeyAdditionList || []),
            'hbg_priceType',
            'ppu_unit',
            'ppu_quantity',
          ],
        }}
        basketProducts={basket.items.reduce((acc: BasketProduct[], item) => {
          acc.push({
            id: item?.product?.sku ? item.product.sku.toString() : undefined,
          })
          return acc
        }, [])}
      />
    )
  }

  if (!(basket?.recommendations || []).length) {
    return null
  }

  const productsRecommendations = basket.recommendations.map((item) => {
    return {
      ...item,
      price: item.price && {
        ...item.price,
        rrpText: i18nText.rrpText,
      },
    }
  })

  return (
    <RecommendationsGridItem colSpan={12} colStart={0}>
      <RecommendationsTitle data-testid="product-recommendations">
        {i18nText.recommendationsTitle}
      </RecommendationsTitle>
      <ProductRecommendations
        products={productsRecommendations}
        itemsPerSlide={[1, 2, 4, 4]}
        trackingContext={RecommendationTrackingContext.BASKET}
      />
    </RecommendationsGridItem>
  )
}
