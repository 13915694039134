import * as React from 'react'
import * as Cookies from 'js-cookie'
import handleViewport from 'react-in-viewport'
import { useRouter } from 'next/router'
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import { useApolloClient } from '@apollo/react-hooks'

import { QubitMode } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'
import { useLogger, useSiteDefinition } from '@thg-commerce/enterprise-core'
import { GridItem, Grid } from '@thg-commerce/gravity-system'
import { styled, Text, spacing, mq } from '@thg-commerce/enterprise-theme'

import {
  PlacementContentArgs,
  PlacementContentType,
  PreviewOptions,
  QubitViewType,
  RecommendationsContent,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'
import { PlacementContent as QUBIT_QUERY } from '@thg-commerce/enterprise-network/src/graphql/Query/Qubit/PlacementContent.graphql'
import { ProductBlockListData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Content/ProductBlockList'
import { ProductBlockListQueryVariables } from '@thg-commerce/enterprise-network/src/generated/graphql'

import {
  sendImpression,
  sendPlacementImpressionEvents,
} from '../qubitImpressions'
import { QubitProductsQuery as QUBIT_PRODUCTS } from '../QubitRecommendations/qubitProductsQuery'
import { ProductRecommendationRail } from '../QubitRecommendations/ProductRecommendationRail'
import { RecommendationSource } from '../QubitRecommendations/types'
import { fetchFallbackData } from '../QubitRecommendations/Fallback'

export interface BasketProduct {
  id: string
}

interface QubitBasketRecommendationProps {
  placementId: string
  mode: QubitMode
  previewOptions?: PreviewOptions
  subTypes?: string[]
  itemColSpan?: number[]
  title?: string
  source: RecommendationSource
  basketProducts: BasketProduct[]
  qubitCallbackURL: string
}

const Title = styled.h2`
  ${Text('large1', 'alternate')};
  margin-bottom: ${spacing(3)};
  text-align: center;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-bottom: ${spacing(4)};
  }
`

const RecommendationsGridItem = styled(GridItem)`
  margin: 0 ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: 0 ${spacing(4)};
  }
`

const ProductsContainerWrapper = styled(Grid)<{ itemsPerRow: number }>`
  place-content: center;
  gap: ${spacing(2)};
  ${(props) =>
    `grid-template-columns: repeat(${Math.min(props.itemsPerRow, 2)}, 1fr)`};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${(props) => `grid-template-columns: repeat(${props.itemsPerRow}, 25%)`};
  }
`
const ProductsContainer = ({
  forwardedRef,
  itemsPerRow,
  children,
}: {
  forwardedRef: React.RefObject<HTMLDivElement>
  itemsPerRow: number
  children: React.ReactNode
}) => {
  return (
    <ProductsContainerWrapper
      itemsPerRow={itemsPerRow}
      forwardedRef={forwardedRef}
    >
      {children}
    </ProductsContainerWrapper>
  )
}

const getQubitRecommendations = async ({
  apolloClient,
  logger,
  config,
}): Promise<{
  title?: string | null
  products: ProductBlockListData
  callBackData?: string
}> => {
  try {
    if (!Cookies.get('_qubitTracker')) {
      return {
        products: await fetchFallbackData(config.source, apolloClient),
      }
    }

    const { qubitContent, qubitCallbackData } = await getQubitPlacementContent(
      apolloClient,
      {
        placementId: config.placementId,
        mode: config.mode,
        previewOptions: {
          experienceId: config.previewOptions?.experienceId,
          campaignId: config.previewOptions?.campaignId,
          group: config.previewOptions?.group,
        },
        attributes: {
          visitor: {
            id: Cookies.get('_qubitTracker'),
            url: `https://${config.currentPageUrl}`,
          },
          user: {},
          basketProducts: config.basketProducts,
          view: {
            currency: config.source.currency,
            type: QubitViewType.BASKET,
            subtypes: config.subTypes || [],
            language: config.language,
          },
        },
        resolveVisitorState: true,
        recsPreFilter: false,
      },
    )

    if (!qubitContent?.recs.length) {
      return {
        products: await fetchFallbackData(config.source, apolloClient),
      }
    }

    const skus = (qubitContent?.recs || []).map((product) => product.id)
    const recommendations = await getRecommendationProducts(apolloClient, {
      skus,
      currency: config.source.currency,
      shippingDestination: config.source.shippingDestination,
      vipPriceEnabled: config.source.vipPriceEnabled,
      clickAndCollectEnabled: config.source.clickAndCollectEnabled,
    })

    return {
      title: qubitContent?.headline,
      products: recommendations,
      callBackData: qubitCallbackData,
    }
  } catch (e) {
    logger.error(`Failed to load qubit recs data with error ${e.message}`)

    try {
      return {
        products: await fetchFallbackData(config.source, apolloClient),
      }
    } catch (fallbackError) {
      console.error(
        `Failed fetching qubit fallback products with error: ${fallbackError}`,
      )
      return {
        products: [],
      }
    }
  }
}

const getQubitPlacementContent = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  variables: PlacementContentArgs,
) => {
  const { data } = await apolloClient.query<{
    qubitPlacements: {
      callbackData: string
      content: {
        [PlacementContentType.RECOMMENDATIONS]: RecommendationsContent
      }
    }
  }>({
    variables,
    query: QUBIT_QUERY,
  })

  return {
    qubitContent:
      data?.qubitPlacements?.content?.[PlacementContentType.RECOMMENDATIONS],
    qubitCallbackData: data?.qubitPlacements?.callbackData,
  }
}

const getRecommendationProducts = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  variables: ProductBlockListQueryVariables & {
    vipPriceEnabled?: boolean
    clickAndCollectEnabled?: boolean
  },
) => {
  const { data } = await apolloClient.query<{
    productBlockList: ProductBlockListData
  }>({
    variables,
    query: QUBIT_PRODUCTS,
  })
  return data?.productBlockList || []
}

export const QubitBasketRecommendations = (
  props: QubitBasketRecommendationProps,
) => {
  const router = useRouter()
  const logger = useLogger()
  const apolloClient = useApolloClient()
  const { defaultLocale, domain } = useSiteDefinition()
  const impressionEmittedRef = React.useRef(false)
  const [qubitRecommendations, setQubitRecommendations] = React.useState<
    ProductBlockListData
  >([])
  const [recommendationTitle, setRecommendationTitle] = React.useState<string>(
    '',
  )
  const [qubitCallbackData, setQubitCallbackData] = React.useState<string>('')

  const ProductsContainerInViewPort = React.useMemo(
    () => handleViewport(ProductsContainer, {}, { disconnectOnLeave: true }),
    [],
  )

  React.useEffect(() => {
    getQubitRecommendations({
      logger,
      apolloClient,
      config: {
        ...props,
        currentPageUrl: `${domain}${router.asPath}`,
        language: defaultLocale.replace(/_/g, '-').toLowerCase(),
      },
    }).then(({ products, title, callBackData }) => {
      setQubitRecommendations(products)
      setRecommendationTitle(title || props.title || '')
      setQubitCallbackData(callBackData || '')
    })
  }, [props.basketProducts.length])

  if (!qubitRecommendations.length) {
    return null
  }

  const onEnterViewport = () => {
    if (impressionEmittedRef.current) {
      return
    }

    sendPlacementImpressionEvents({
      logger,
      sendPlacementLevelImpression: sendImpression,
      productId: qubitRecommendations?.map((product) => product.sku),
      callbackData: qubitCallbackData,
      callbackURL: props.qubitCallbackURL,
    })

    impressionEmittedRef.current = true
  }

  const Products = qubitRecommendations.map((product, index) => (
    <GridItem key={index}>
      <ProductRecommendationRail
        product={product}
        qubitCallbackData={qubitCallbackData}
      />
    </GridItem>
  ))

  return (
    <RecommendationsGridItem colSpan={12}>
      {recommendationTitle && <Title>{recommendationTitle}</Title>}
      <ProductsContainerInViewPort
        itemsPerRow={Math.min(qubitRecommendations.length, 4)}
        onEnterViewport={onEnterViewport}
      >
        {Products}
      </ProductsContainerInViewPort>
    </RecommendationsGridItem>
  )
}
