import * as React from 'react'
import { Helmet } from 'react-helmet'

export const AmpCarousel = (props) => {
  const { children, className } = props
  return (
    <React.Fragment>
      <Helmet key="helmet">
        <script
          async
          custom-element="amp-carousel"
          src="https://cdn.ampproject.org/v0/amp-carousel-0.2.js"
        />
      </Helmet>
      <amp-carousel {...props} className={className}>
        {children}
      </amp-carousel>
    </React.Fragment>
  )
}

export default AmpCarousel
