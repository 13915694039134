import * as React from 'react'
import { Helmet } from 'react-helmet'

import { createGlobalStyle } from '@thg-commerce/gravity-theme'

const StyledAmpImg = createGlobalStyle`
  amp-img {
    &.contain {
      img {
        object-fit: contain;
      }
    }
    &.cover {
      img {
        object-fit: cover;
      }
    }
  }
`

export const AmpImage = (props) => {
  const { children, className } = props
  return (
    <React.Fragment>
      <Helmet key="helmet">
        <script
          async
          custom-element="amp-img"
          src="https://cdn.ampproject.org/v0.js"
        />
      </Helmet>
      <StyledAmpImg />
      <amp-img className={className} {...props}>
        {children}
      </amp-img>
    </React.Fragment>
  )
}

export default AmpImage
