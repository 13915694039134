import * as React from 'react'
import loadable from '@loadable/component'

import { WishlistButton } from '@thg-commerce/enterprise-components'
import {
  Button,
  DrawFocus,
  PlatformMessage,
  SafeHtml,
} from '@thg-commerce/gravity-elements'
import { TextStyling } from '@thg-commerce/gravity-patterns'
import {
  mq,
  padding,
  spacing,
  styled,
  Text,
  TextStyle,
} from '@thg-commerce/gravity-theme'

import { TableComponents } from '../../theme'

const Close = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Trash'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const columnWidthMap = {
  image: 'auto',
  name: '1fr',
  price: '108px',
  quantity: '144px',
  subtotal: '100px',
  delete: '48px',
}

const formatGridAreas = (
  type: string,
  components?: string[],
  fullWidth?: boolean,
) => {
  const gridTemplate: string[] = []

  if (components) {
    for (let i = 0; i < components.length; i += 1) {
      gridTemplate.push(fullWidth ? type : 'limit')
    }

    if (!fullWidth) {
      gridTemplate[0] = components[0]
      gridTemplate[1] = type
    }

    return `"${gridTemplate.join(' ')}"`
  }

  return `"image ${type} . limit limit limit"`
}

interface MobileGridComponents {
  components: TableComponents
}

const formatMobileGridAreas = (props: MobileGridComponents) => {
  const tableComponents = props?.components?.xs?.order || [
    'price',
    'subtotal',
    'discount',
    'supersize',
    'promomessage',
    'wishlist',
    'dropdown',
    'itemerrors',
    'clickandcollect',
    'quantity',
    'limit',
    'outofstockerror',
    'subscriptionInfoMessage',
  ]
  const returnableItem: string[] = [
    "'image name delete'",
    "'image price delete'",
    "'image subtotal delete'",
  ]

  tableComponents.map((component) => {
    if (
      component === 'wishlist' &&
      !props.components?.xs?.fullWidth?.includes(component)
    ) {
      returnableItem.push("'. wishlist wishlist'")
    } else if (component === 'quantity' || props[component]) {
      returnableItem.push(
        props.components?.xs?.fullWidth?.includes(component)
          ? props.components?.xs?.enableContainerGridMobileWidth
            ? `'. ${component} ${component}'`
            : `'${component} ${component} ${component}'`
          : `'. ${component} .'`,
      )
    }
  })

  return returnableItem.join(' ')
}

export const Container = styled.div<{
  discount?: boolean
  wishlist?: boolean
  limit: boolean
  clickandcollect?: boolean
  itemerrors?: boolean
  outofstockerror?: boolean
  supersize?: boolean
  promomessage?: boolean
  dropdown?: boolean
  subscriptionInfoMessage?: boolean
  components?: TableComponents
  gridRowGapSpacing: number
}>`
  display: grid;
  display: -ms-grid;
  grid-column-gap: ${spacing(2)};
  grid-row-gap: ${(props) => spacing(props.gridRowGapSpacing)};
  grid-template-columns: auto 1fr auto;
  -ms-grid-columns: auto 1fr auto;
  grid-template-rows: repeat(
    ${({
      promomessage,
      wishlist,
      discount,
      limit,
      clickandcollect,
      itemerrors,
      outofstockerror,
    }) =>
      10 -
      [
        promomessage,
        wishlist,
        discount,
        limit,
        clickandcollect,
        itemerrors,
        outofstockerror,
      ].filter((value) => !value).length},
    auto
  );
  -ms-grid-rows: (auto) [
    ${({
      promomessage,
      wishlist,
      discount,
      limit,
      clickandcollect,
      itemerrors,
      outofstockerror,
    }) =>
      10 -
      [
        promomessage,
        wishlist,
        discount,
        limit,
        clickandcollect,
        itemerrors,
        outofstockerror,
      ].filter((value) => !value).length}];
  grid-template-areas: ${(props) => formatMobileGridAreas(props)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    grid-template-columns: ${(props) =>
      props.components?.sm?.order
        ? props.components?.sm?.order.map(
            (component) => `${columnWidthMap[component]} `,
          )
        : 'auto 1fr 108px 144px 100px 48px'};
    -ms-grid-columns: ${(props) =>
      props.components?.sm?.order
        ? props.components?.sm?.order.map(
            (component) => `${columnWidthMap[component]} `,
          )
        : 'auto 1fr 108px 144px 100px 48px'};

    grid-template-rows: repeat(
      ${({
        promomessage,
        wishlist,
        dropdown,
        discount,
        limit,
        clickandcollect,
        itemerrors,
        outofstockerror,
      }) =>
        8 -
        [
          discount,
          promomessage,
          wishlist,
          dropdown,
          clickandcollect,
          itemerrors,
          outofstockerror,
          limit &&
            !discount &&
            !wishlist &&
            !clickandcollect &&
            !itemerrors &&
            !outofstockerror,
        ].filter((value) => !value).length},
      auto
    );
    -ms-grid-rows: (auto) [
      ${({
        promomessage,
        wishlist,
        dropdown,
        discount,
        limit,
        clickandcollect,
        itemerrors,
        outofstockerror,
      }) =>
        8 -
        [
          promomessage,
          discount,
          wishlist,
          dropdown,
          clickandcollect,
          itemerrors,
          limit &&
            !discount &&
            !wishlist &&
            !clickandcollect &&
            !itemerrors &&
            !outofstockerror,
        ].filter((value) => !value).length}];
    grid-template-areas:
      '${(props) =>
        props.components?.sm?.order
          ? props.components?.sm?.order?.join(' ')
          : 'image name price quantity subtotal delete'}'
      ${(props) =>
        props.discount &&
        formatGridAreas('discount', props.components?.sm?.order)}
      ${(props) =>
        props.supersize &&
        formatGridAreas('supersize', props.components?.sm?.order)}
      ${(props) =>
        props.promomessage &&
        formatGridAreas('promomessage', props.components?.sm?.order)}
      ${(props) =>
        props.wishlist &&
        formatGridAreas('wishlist', props.components?.sm?.order)}
      ${(props) =>
        props.dropdown &&
        formatGridAreas('dropdown', props.components?.sm?.order)}
      ${(props) =>
        props.clickandcollect &&
        formatGridAreas('clickandcollect', props.components?.sm?.order)}
      ${(props) =>
        props.itemerrors &&
        formatGridAreas('itemerrors', props.components?.sm?.order)}
      ${(props) =>
        props.limit &&
        !props.wishlist &&
        !props.discount &&
        !props.clickandcollect &&
        !props.outofstockerror &&
        !props.itemerrors &&
        formatGridAreas('wishlist', props.components?.sm?.order)}
      ${(props) =>
        props.outofstockerror &&
        formatGridAreas('outofstockerror', props.components?.sm?.order)}
      ${(props) =>
        props.subscriptionInfoMessage && props.components?.sm?.order
          ? props.components?.sm?.enableContainerGridMobileWidth
            ? `"image subscriptionInfoMessage subscriptionInfoMessage subscriptionInfoMessage subscriptionInfoMessage"`
            : formatGridAreas(
                'subscriptionInfoMessage',
                props.components?.sm?.order,
                true,
              )
          : props.subscriptionInfoMessage
          ? `"image subscriptionInfoMessage subscriptionInfoMessage subscriptionInfoMessage subscriptionInfoMessage subscriptionInfoMessage"`
          : ''};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    grid-column-gap: ${spacing(4)};
  }
`
export const ProductImageLink = styled.a`
  width: 77px;
  grid-area: image;
  ${DrawFocus()}
`

export const Name = styled.a`
  text-decoration: none;
  grid-area: name;
  ${DrawFocus()}
`

export const Discount = styled.div`
  grid-area: discount;
`
export const DisclaimerText = styled.div`
  ${padding({
    top: 1.5,
  })};
  ${Text('small', 'default')};
`

export const StyledPlatformMessage = styled(PlatformMessage)`
  background: none;
  padding: 0;
`

export const UnitPrice = styled.div<{
  hide?: boolean
  fontStyle: TextStyle
  textColor?: string
  showOnMobile?: boolean
}>`
  grid-area: price;
  display: none;
  width: 100px;

  ${(props) => Text('bodyText', props.fontStyle)}

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    align-self: center;
  }

  ${(props) =>
    mq(props.theme.breakpointUtils.map, props.showOnMobile ? 'xs' : 'md')} {
    display: ${(props) => (props.hide ? 'none' : 'block')};
  }

  color: ${(props) => props.textColor || 'inherit'};
`

export const RrpPrice = styled.div<{ textStyling?: TextStyling }>`
  display: inline;
  padding-right: ${spacing(1)};

  ${(props) =>
    Text(
      props.textStyling?.entry || 'small',
      props.textStyling?.style || 'alternate',
    )};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: block;
  }

  color: ${(props) => props.textStyling?.textColor || 'inherit'};
  text-decoration: ${(props) => props.textStyling?.textDecoration};
`

export const TotalPrice = styled.div<{ fontStyle: TextStyle }>`
  grid-area: subtotal;

  ${(props) => Text('bodyText', props.fontStyle)}

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: 70px;
    align-self: center;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    width: 100px;
  }
`

export const TotalPriceLabel = styled.div`
  display: inline;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: none;
  }
`

export const QuantitySelectorContainer = styled.div`
  grid-area: quantity;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: auto 0;
    width: 144px;
  }
`

export const QuantityPlaceholder = styled.div`
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: 144px;
    text-align: center;
  }
`

export const MaxOrderMessage = styled(PlatformMessage)`
  grid-area: limit;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`

export const WishlistContainer = styled(WishlistButton)`
  grid-area: wishlist;
  justify-self: flex-start;
  align-self: flex-start;
`

export const RemoveItemContainer = styled.div`
  grid-area: delete;
  width: 40px;
  height: 40px;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    align-self: center;
  }
`
export const PromotionProductMessage = styled(SafeHtml)`
  ${Text('bodyText', 'alternate')};
  color: ${(props) => props.theme.colors.error.base};

  grid-area: promomessage;
  &:last-child {
    padding-bottom: 0;
  }
`

export const ClickAndCollect = styled.div`
  grid-area: clickandcollect;
`

export const ClickAndCollectContainer = styled.div`
  display: grid;
  align-items: center;
  margin-bottom: ${spacing(1)};
  justify-content: start;
  justify-items: left;
`

export const CollectInStore = styled.div`
  display: grid;
  align-items: center;
  column-gap: ${spacing(1)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: flex;
  }
`

export const ClickAndCollectButtonContainer = styled.div`
  grid-area: clickandcollect;
  grid-column-start: auto;
  justify-self: flex-start;
  align-self: flex-start;
`

export const RemoveItem = styled.button`
  ${DrawFocus()}

  cursor: pointer;
  width: 40px;
  height: 40px;
  ${DrawFocus()}
`

export const Separator = styled.hr`
  border-top: 1px solid ${(props) => props.theme.colors.palette.greys.light};
  margin: ${spacing(1)} 0 ${spacing(3)} 0;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: ${spacing(3)} 0;
  }
`

export const IconContainer = styled.span`
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

export const StyledClose = styled(Close)`
  width: 18px;
  height: 18px;
`

export const SupersizeButton = styled(Button)`
  white-space: nowrap;
  width: 140px;
  height: 50px;
`
export const SupersizeContainer = styled.div`
  grid-area: supersize;
  display: flex;
  flex-direction: column;
  gap: ${spacing(1)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    flex-direction: row;
  }
`
export const SubscriptionContainer = styled.div`
  grid-area: dropdown;

  > div:nth-child(2) > button > div {
    text-transform: lowercase;
  }
`

export const SubscriptionInfoMessageContainer = styled.div`
  margin-top: ${spacing(2)};
  grid-area: subscriptionInfoMessage;
`

export const SupersizeMessage = styled.p`
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    align-self: center;
    justify-self: center;
  }
`
export const FulfilmentMethodText = styled.p`
  ${Text('bodyText', 'alternate')}
`

export const StoreName = styled.span`
  font-weight: bold;
  display: flex;
  flex-direction: column;
`

export const PersonalisationValuesContainer = styled.div`
  display: flex;
  & > :not(:last-child)::after {
    content: '-';
  }
`
