import * as React from 'react'
import { Helmet } from 'react-helmet'

import { styled } from '@thg-commerce/gravity-theme'

const AmpAccordionHeader = styled.h2`
  padding: 0;
  background: transparent;
  border: 2px solid transparent;

  &:focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const AmpAccordion = <T extends object>(
  props: T & { children: React.ReactNode; header: React.ReactNode },
) => {
  const { children, header, ...rest } = props

  return (
    <React.Fragment>
      <Helmet key="helmet">
        <script
          async
          custom-element="amp-accordion"
          src="https://cdn.ampproject.org/v0/amp-accordion-0.1.js"
        />
      </Helmet>
      <amp-accordion animate="" {...rest} key="accordion">
        <section>
          <AmpAccordionHeader>{header}</AmpAccordionHeader>
          <div>{children}</div>
        </section>
      </amp-accordion>
    </React.Fragment>
  )
}

export default AmpAccordion
