import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import debounce from 'lodash.debounce'
import dynamic from 'next/dynamic'
import { vsprintf } from 'sprintf-js'

import {
  ContentItem,
  SubscriptionInfoBox,
} from '@thg-commerce/enterprise-components/Product'
import { WishlistButtonType } from '@thg-commerce/enterprise-components/Product/src/WishlistButton/WishlistButton'
import { SubscriptionFrequencyDropDown } from '@thg-commerce/enterprise-components/SubscriptionFrequencyDropDown'
import {
  EnterpriseContext,
  i18n,
  MessageArea,
  useBasketId,
  useEnterpriseContext,
  useFormattableI18nProperty,
  useHorizonSessionSettings,
  useSiteConfig,
  useTransmit,
} from '@thg-commerce/enterprise-core'
import { InteractionLocation } from '@thg-commerce/enterprise-core/src/Basket'
import { useBasket } from '@thg-commerce/enterprise-core/src/Basket/hooks/useBasketId'
import { SubscriptionFrequencyUnit } from '@thg-commerce/enterprise-graphql/horizon-schema'
import { useBackendEventNormaliser } from '@thg-commerce/enterprise-metrics'
import {
  pushFulfilmentData,
  pushToEnhancedDataLayer,
  pushToEventGA4,
} from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'
import { ChangeProductSubscriptionContractInBasketData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Mutation/ChangeProductSubscriptionContractInBasket'
import {
  RemoveItemData,
  RemoveItemVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Mutation/RemoveProductFromBasket'
import {
  SuperSizeProductInBasketData,
  SuperSizeProductInBasketVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Mutation/SuperSizeProductInBasket'
import {
  UpdateItemQuantityData,
  UpdateItemQuantityVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Mutation/UpdateProductQuantityInBasket'
import { BasketData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/Basket'
import {
  FreeTextProductPersonalisationField,
  MultiSelectionProductPersonalisationField,
  SingleSelectionProductPersonalisationField,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Product'
import {
  Feature,
  FulfilmentMethod,
  MutationChangeProductSubscriptionContractInBasketArgs,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  TableComponents,
  UnitPrice as UnitPriceTheme,
  useTheme,
} from '@thg-commerce/enterprise-pages/src/Basket/theme'
import {
  AriaMessageType,
  PlatformMessage,
  ProductImage,
} from '@thg-commerce/gravity-elements'
import { OptionsType } from '@thg-commerce/gravity-elements/CustomDropdown/CustomDropdown'
import {
  QuantitySelector,
  QuantitySelectorProps,
} from '@thg-commerce/gravity-elements/QuantitySelector/QuantitySelector'
import { TextStyle } from '@thg-commerce/gravity-theme'

import { Subtitle } from '../styles'

import { FulfilmentMethodsMessage } from './FulfilmentMethodsMessage/FulfilmentMethodsMessage'
import { RemoveBasketItem } from './RemoveItem/RemoveItem'
import { ChangeProductSubscriptionContractInBasket as CHANGE_FREQUENCY_MUTATION } from './ChangeProductSubscriptionContractInBasket.graphql'
import { Message } from './Message'
import {
  ClickAndCollect,
  Container,
  DisclaimerText,
  Discount,
  MaxOrderMessage,
  Name,
  PersonalisationValuesContainer,
  ProductImageLink,
  PromotionProductMessage,
  QuantityPlaceholder,
  QuantitySelectorContainer,
  RrpPrice,
  StyledPlatformMessage,
  SubscriptionContainer,
  SubscriptionInfoMessageContainer,
  SupersizeButton,
  SupersizeContainer,
  SupersizeMessage,
  TotalPrice,
  TotalPriceLabel,
  UnitPrice,
  WishlistContainer,
} from './styles'

const SvgAccountSubscriptionsOutline = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/AccountIcons/AccountSubscriptionsOutline'
    ),
)

export const UPDATE_PRODUCT_QUANTITY_MUTATION = gql`
  mutation UpdateProductQuantityInBasket(
    $basketId: ID
    $itemId: ID!
    $quantity: Int!
    $settings: SessionSettings!
  ) {
    updateProductQuantityInBasket(
      basketId: $basketId
      itemId: $itemId
      quantity: $quantity
      settings: $settings
    ) @client {
      id
    }
  }
`

export const REMOVE_PRODUCT_MUTATION = gql`
  mutation RemoveProductFromBasket(
    $basketId: ID
    $itemId: ID!
    $settings: SessionSettings!
  ) {
    removeProductFromBasket(
      basketId: $basketId
      itemId: $itemId
      settings: $settings
    ) @client {
      id
    }
  }
`

export const SUPERIZE_PRODUCT_IN_BASKET = gql`
  mutation supersizeProductInBasket(
    $basketId: ID
    $itemId: ID!
    $settings: SessionSettings!
  ) {
    supersizeProductInBasket(
      basketId: $basketId
      itemId: $itemId
      settings: $settings
    ) @client {
      id
    }
  }
`

const renderAnnouncer = (ariaLive: AriaMessageType, message: string) => {
  return <MessageArea aria-live={ariaLive} message={message} />
}

interface PersonalisationValue {
  name: string
  value: string
}

export type ItemProps = BasketData['items'][0] & {
  discountMessages?: string[]
  personalisationValues?: PersonalisationValue[]
  product: Omit<BasketData['items'][0]['product'], 'content'> & {
    variantIdentifier?: string
    availableFrequencies: OptionsType[]
    personalisationFields?: [
      | MultiSelectionProductPersonalisationField
      | FreeTextProductPersonalisationField
      | SingleSelectionProductPersonalisationField,
    ]
    product?: {
      basketImage: string
      title: string
      externalIdentifier?: string
    }
    content?: ContentItem[]
  }
  index?: number
}

interface PersonalisationProps {
  disclaimerMessage: string
  personalisationValues?: PersonalisationValue[]
}

const getProductCategory = (
  product: BasketData['items'][0]['product'],
): string =>
  product.product?.breadcrumbs
    ?.filter((breadcrumb) => breadcrumb.pagePath !== '/')
    .map((breadcrumb) => breadcrumb.displayName)
    .join('/') || ''

const PersonalisationValues = (props: PersonalisationProps) => {
  return (
    <React.Fragment>
      <PersonalisationValuesContainer>
        {props.personalisationValues &&
          props.personalisationValues.length > 0 &&
          props.personalisationValues.map((item) => (
            <DisclaimerText>{item.value}</DisclaimerText>
          ))}
      </PersonalisationValuesContainer>
      <DisclaimerText>{props.disclaimerMessage}</DisclaimerText>
    </React.Fragment>
  )
}

const ProductName = (props: {
  title: string
  personalisation: PersonalisationProps
}) => {
  if (
    props.personalisation.personalisationValues &&
    props.personalisation.personalisationValues.length > 0
  ) {
    return (
      <React.Fragment>
        <div>{props.title}</div>
        <PersonalisationValues
          disclaimerMessage={props.personalisation.disclaimerMessage}
          personalisationValues={props.personalisation.personalisationValues}
        />
      </React.Fragment>
    )
  }
  return <React.Fragment>{props.title}</React.Fragment>
}

const FreeGift = (props: {
  title: string
  personalisation: PersonalisationProps
  imageUrls?: string
}) => {
  return (
    <React.Fragment>
      <div style={{ width: '77px', gridArea: 'image' }}>
        <ProductImage
          data-testid="product-image"
          alt={props.title}
          urls={{
            largeProduct: props.imageUrls,
          }}
          width={[73]}
        />
      </div>
      <div style={{ textDecoration: 'none', gridArea: 'name' }}>
        <ProductName
          title={props.title}
          personalisation={props.personalisation}
        />
      </div>
    </React.Fragment>
  )
}

const ProductLink = (props: {
  url: string
  title: string
  personalisation: PersonalisationProps
  imageUrls?: string
}) => {
  return (
    <React.Fragment>
      <ProductImageLink href={props.url}>
        <ProductImage
          data-testid="product-image"
          alt={props.title}
          urls={{
            largeProduct: props.imageUrls,
          }}
          width={[73]}
        />
      </ProductImageLink>
      <Name href={props.url}>
        <ProductName
          title={props.title}
          personalisation={props.personalisation}
        />
      </Name>
    </React.Fragment>
  )
}

export const Item = (props: ItemProps) => {
  const [basketId, setBasketId] = useBasketId()
  const sessionSettings = useHorizonSessionSettings()
  const {
    enableWishlists,
    enableWishlistsGlobal,
    enableWishlistOnBasketItem,
    enhancedEcommerceEnabled,
    showBasketItemOutOfStockMessage,
    useExternalIdentifier,
    useExternalIdentifierInSchema,
    useGA4EnhancedEcom,
    displayBasketWithStandardPrice,
    showParentTitleAddToCartGA4,
  } = useSiteConfig()

  const theme = useTheme()

  const transmit = useTransmit()
  const normaliseBackendEvent = useBackendEventNormaliser()
  const { extensionsRef } = React.useContext(EnterpriseContext)
  const {
    value: [getExtensions],
  } = extensionsRef
  const { horizonFeatures } = useEnterpriseContext()
  const { basket } = useBasket()

  const [selectedFrequencyId, updateSelectedFrequencyId] = React.useState<
    string
  >(props.subscriptionContract?.id || '')

  const removePressed = React.useRef(false)
  const quantityIsUpdating = React.useRef(false)

  const showWishlist =
    enableWishlists &&
    enableWishlistsGlobal &&
    enableWishlistOnBasketItem &&
    !props.freeGift

  const hasRrpPrice =
    props.product.price?.price?.amount !== props.product.price?.rrp?.amount

  const showDropDownOrSubscriptionInfoBox =
    props.product.isSubscription ||
    !!props.product.subscriptionContracts?.length

  const clickAndCollectEnabled = !!horizonFeatures?.includes(
    Feature.ClickAndCollect,
  )

  const maxProductLimit = props.product.maxPerOrder || 99
  const maxProductQuantityReached = props.quantity >= maxProductLimit

  const hasDiscounts =
    props.discountMessages && props.discountMessages.length > 0

  const showItemErrors = !!(props.messages && props.fulfilmentMethod)

  const isSubscriptionEnabled =
    horizonFeatures?.includes(Feature.Subscriptions) ||
    horizonFeatures?.includes(Feature.SubscribeAndSave) ||
    false

  const trackEnhancedEvent = (event: string, key: string, quantity: number) => {
    if (enhancedEcommerceEnabled) {
      const homeDeliveryAvailable = props.product?.eligibleForFulfilmentMethods
        ? Boolean(
            props.product?.eligibleForFulfilmentMethods?.includes(
              FulfilmentMethod.HomeDelivery,
            ),
          )
        : true
      const clickAndCollectAvailable = props.product
        ?.eligibleForFulfilmentMethods
        ? Boolean(
            props.product?.eligibleForFulfilmentMethods?.includes(
              FulfilmentMethod.CollectInStore,
            ),
          )
        : false

      const productViewData = pushFulfilmentData(
        props.product.inStock,
        homeDeliveryAvailable,
        clickAndCollectAvailable,
        props.fulfilmentMethod,
      )

      pushToEnhancedDataLayer({
        event,
        ecommerce: {
          [key]: {
            products: [
              {
                quantity,
                id:
                  (useExternalIdentifier || useExternalIdentifierInSchema) &&
                  props.product.externalIdentifier
                    ? props.product.externalIdentifier
                    : props.product.sku,
                price: props.standardPricePerUnit.amount,
                name: props.product.title,
                brand: props.product.product?.brand?.name,
                category: getProductCategory(props.product),
                ...(clickAndCollectEnabled && {
                  ...productViewData,
                  price: props.chargePricePerUnit.amount,
                }),
              },
            ],
          },
        },
      })
    }
  }
  const productTitle =
    showParentTitleAddToCartGA4 && props.product?.product?.title
      ? props.product?.product?.title
      : props.product.title

  const trackGA4event = (eventName: string, quantity: number): void => {
    useGA4EnhancedEcom &&
      pushToEventGA4({
        event: 'ecom_event',
        event_name: eventName,
        interactionLocation: InteractionLocation.BASKET,
        ecommerce: {
          currencyCode: sessionSettings.currency,
          items: [
            {
              quantity,
              item_name: productTitle,
              item_id:
                (useExternalIdentifier || useExternalIdentifierInSchema) &&
                props.product.product?.externalIdentifier
                  ? props.product.product.externalIdentifier
                  : props.product.product?.sku.toString(),
              item_variant:
                (useExternalIdentifier || useExternalIdentifierInSchema) &&
                props.product.variantIdentifier
                  ? props.product.variantIdentifier
                  : props.product.sku.toString(),
              price: props.standardPricePerUnit.amount,
              item_brand: props.product.product?.brand?.name,
              item_category: getProductCategory(props.product),
              item_list_name: productTitle,
              index: 0,
            },
          ],
        },
      })
  }

  const trackBackendEvent = (quantity: number, subtype: string) => {
    const extensions = getExtensions()

    transmit({
      type: 'cart_interaction_event',
      payload: normaliseBackendEvent({
        basket,
        eventData: {
          subtype,
          type: 'cart_interaction_event',
          items: [
            {
              quantity,
              product_group: {
                id: props.product.product?.sku,
                selected_variant: {
                  price: {
                    currency: sessionSettings.currency,
                    value: Number(props.standardPricePerUnit.amount),
                  },
                  sku: props.product.sku,
                },
                total_variants: props.product.product?.variants
                  ? props.product.product?.variants.length
                  : 1,
              },
            },
          ],
        },
        rays: [extensions?.ray || ''],
        experiments: extensions?.experiments,
        requestData: {
          ...(extensions?.LoggerLinkData || {
            start_timestamp: Date.now(),
            duration_ms: 0,
          }),
          url: window.location.href,
        },
      }),
    })
  }

  const [updateSubscription] = useMutation<
    {
      changeProductSubscriptionContractInBasket: ChangeProductSubscriptionContractInBasketData
    },
    MutationChangeProductSubscriptionContractInBasketArgs
  >(CHANGE_FREQUENCY_MUTATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      data?.changeProductSubscriptionContractInBasket?.id &&
        setBasketId(data.changeProductSubscriptionContractInBasket.id)
    },
  })

  const [supersizeProduct] = useMutation<
    { supersizeProductInBasket: SuperSizeProductInBasketData },
    SuperSizeProductInBasketVariables
  >(SUPERIZE_PRODUCT_IN_BASKET, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      data?.supersizeProductInBasket.id &&
        setBasketId(data.supersizeProductInBasket.id)
    },
  })
  const [updateQuantity] = useMutation<
    { updateProductQuantityInBasket: UpdateItemQuantityData },
    UpdateItemQuantityVariables
  >(UPDATE_PRODUCT_QUANTITY_MUTATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      data.updateProductQuantityInBasket &&
        setBasketId(data.updateProductQuantityInBasket.id)
      quantityIsUpdating.current = false
    },
  })

  const [removeItem] = useMutation<
    { removeProductFromBasket: RemoveItemData },
    RemoveItemVariables
  >(REMOVE_PRODUCT_MUTATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      data &&
        data.removeProductFromBasket &&
        setBasketId(data.removeProductFromBasket.id)

      trackEnhancedEvent('removeFromCart', 'remove', props.quantity)
      trackBackendEvent(-props.quantity, 'trash')

      trackGA4event('remove_from_cart', props.quantity)
    },
  })

  const i18nText = {
    subtotalLabel: i18n('basket.subtotal.label'),
    removeItemLabel: i18n('basket.removeitem.label'),
    supersizeButton: i18n('basket.superize.button.text'),
    increaseButtonLabel: i18n('general.quantity.increase'),
    decreaseButtonLabel: i18n('general.quantity.decrease'),
    quantityLabel: i18n('general.quantity.text'),
    freeGift: i18n('basket.freegift.text'),
    free: i18n('product.freeprice.text'),
    disclaimerMessage: i18n('personalisation.item.disclaimer.text'),
    maxOrderText: i18n(
      'basket.item.max.quantity.reached',
      maxProductLimit.toString(),
    ),
    productOutOfStockError: i18n('basket.item.product.outofstock.error.text'),
    supersizeMessagePart1: i18n('basket.supersizemessage.part1'),
    supersizeMessagePart2: i18n('basket.supersizemessage.part2'),
    supersizeMessagePart3: i18n('basket.supersizemessage.part3'),
    discountMessage1: useFormattableI18nProperty(
      'basket.rrp.discountsubscribe.message',
    ),
    discountMessage2: useFormattableI18nProperty(
      'basket.rrp.discount.freedelivery.message',
    ),
    dropDownTitle: i18n(
      'product.subscriptions.subscriptionfrequencyselector.title',
    ),
    subscriptionInformationTitle: i18n('basket.subscription.information.title'),
    subscriptionInformationNote1: i18n('basket.subscription.information.note'),
    subscriptionInformationNote2: i18n(
      'basket.subscription.information.shipped.note',
    ),
    subscriptionDiscountPercentage: useFormattableI18nProperty(
      'basket.subscription.information.percentage.text',
    ),
    subscriptionDurationSingularWeeks: i18n(
      'basket.subscription.information.duration.single.week.text',
    ),
    subscriptionDurationSingularMonths: i18n(
      'basket.subscription.information.duration.single.month.text',
    ),
    subscriptionDurationSingularYears: i18n(
      'basket.subscription.information.duration.single.year.text',
    ),
    subscriptionDurationMultipleWeeks: useFormattableI18nProperty(
      'basket.subscription.information.duration.multiple.weeks.text',
    ),
    subscriptionDurationMultipleMonths: useFormattableI18nProperty(
      'basket.subscription.information.duration.multiple.text',
    ),
    subscriptionDurationMultipleYears: useFormattableI18nProperty(
      'basket.subscription.information.duration.multiple.years.text',
    ),
  }

  const wishlistI18nText = {
    addToWishlistText: i18n('product.addtowishlistbutton.addtowishlist.text'),
    savedToWishlistText: i18n(
      'product.addtowishlistbutton.savedtowishlist.text',
    ),
    wishlistTooltip: {
      closeButtonText: i18n('product.addtowishlist.tooltip.button.close.text'),
    },
    wishlistTooltipContent: {
      loginSignupText: i18n('product.addtowishlist.tooltip.loginsignup.text'),
      toUseWishlistText: i18n('product.addtowishlist.tooltip.text'),
    },
  }

  const fulfilmentMethodsI18nText = {
    basketFulfilmentChangeStore: i18n('basket.item.fulfilment.change.store'),
    basketFulfilmentChangeStoreAriaLabel: i18n(
      'basket.item.fulfilment.change.store.aria.label',
    ),
    basketFulfilmentChangeHomeDelivery: i18n(
      'basket.item.fulfilment.change.to.home.delivery',
    ),
    basketFulfilmentChangeHomeDeliveryAriaLabel: i18n(
      'basket.item.fulfilment.change.to.home.delivery.aria.label',
    ),
    basketFulfilmentNamedDayDelivery: i18n(
      'basket.item.fulfilment.nameddaydelivery',
    ),
    basketFulfilmentNamedDayDeliveryOptions: i18n(
      'basket.item.fulfilment.nameddaydelivery.options',
    ),
    basketFulfilmentNextDayDelivery: i18n(
      'basket.item.fulfilment.nextdaydelivery',
    ),
    basketFulfilmentNextDayDeliveryOptions: i18n(
      'basket.item.fulfilment.nextdaydelivery.options',
    ),
    basketFulfilmentCollectInStore: i18n(
      'basket.item.fulfilment.collectinstore',
    ),
    basketFulfilmentDeliverToStore: i18n(
      'basket.item.fulfilment.delivertostore',
    ),
    basketFulfilmentCollectInStoreOutOfStock: i18n(
      'basket.item.fulfilment.collectinstore.notinstock',
    ),
    basketFulfilmentCollectInStoreStock: (storeNumber: string) =>
      i18n('basket.item.fulfilment.collectinstore.stock', storeNumber),
    basketFulfilmentChangeCollectInStore: i18n(
      'basket.item.fulfilment.changetocollectinstore',
    ),
    basketFulfilmentLeadTime: i18n(
      'basket.item.fulfilment.leadtime',
      props.product.leadTime?.toString(),
    ),
    dynamicDeliveryText: {
      homeDelivery: {
        isAvailable: i18n('product.delivery.home.instock.text'),
        isNotAvailable: i18n('product.delivery.home.unavailable.text'),
        isOutOfStock: i18n('product.delivery.home.outofstock.text'),
        datedDelivery: i18n(
          'basket.item.fulfilment.leadtime',
          props.product.leadTime?.toString(),
        ),
        nextDayDelivery: i18n('basket.item.fulfilment.nextdaydelivery'),
        oneManDelivery: i18n(
          'product.item.fulfilment.1man.nextdaydeliveryavailable',
        ),
        outOfGaugeDelivery: i18n(
          'product.item.fulfilment.outofgauge.nameddaydeliveryavailable',
        ),
        dynamicDelivery: i18n(
          `product.item.fulfilment.pdp.${props.product.weightGroups?.[0]?.toLowerCase()}`,
        ),
      },
    },
  }

  const updateSubscriptionCallback = (contractId: string) => {
    updateSubscription({
      variables: {
        contractId,
        basketId,
        settings: sessionSettings,
        sku: props.product.sku,
        toSubscription: true,
      },
    })
  }

  const quantitySelectorProps: QuantitySelectorProps = {
    i18nText: {
      quantityLabel: i18nText.quantityLabel,
      decreaseButtonLabel: i18nText.decreaseButtonLabel,
      increaseButtonLabel: i18nText.increaseButtonLabel,
    },
    disabled: removePressed.current || quantityIsUpdating.current,

    maxValue: maxProductLimit,
    quantity: props.quantity,
    quantityChangedCallback: debounce(async (quantity) => {
      if (quantity === 0) {
        removeItem({
          variables: {
            basketId,
            settings: sessionSettings,
            itemId: props.id,
          },
        })
      } else if (quantity) {
        quantityIsUpdating.current = true

        await updateQuantity({
          variables: {
            quantity,
            basketId,
            settings: sessionSettings,
            itemId: props.id,
          },
        })

        if (props.quantity < quantity) {
          trackEnhancedEvent('addToCart', 'add', quantity - props.quantity)
          trackBackendEvent(quantity, 'quantity_increase')
          trackGA4event('add_to_cart', quantity - props.quantity)
        } else {
          trackEnhancedEvent(
            'removeFromCart',
            'remove',
            Math.abs(quantity - props.quantity),
          )
          trackGA4event('remove_from_cart', Math.abs(quantity - props.quantity))
          trackBackendEvent(quantity, 'quantity_decrease')
        }
      }
    }, 50),
    showInnerBorders: true,
  }

  const discountMessages =
    props.discountMessages?.map((message, index) => (
      <StyledPlatformMessage
        key={`${message}-${index}`}
        text={props.freeGift ? i18nText.freeGift : message}
        type="success"
      />
    )) || []

  const supersizeMessage = vsprintf('%s %s %s: %s %s %s %s', [
    i18nText.supersizeMessagePart1,
    props.product.supersize?.saving.nextSupersizeOption.value,
    props.product.supersize?.saving.nextSupersizeOption.unit,
    i18nText.supersizeMessagePart2,
    props.product.supersize?.saving.savingPerUnit.displayValue,
    i18nText.supersizeMessagePart3,
    props.product.supersize?.saving.savingUnit.unit,
  ])

  const promotionalMessage = showDropDownOrSubscriptionInfoBox
    ? props.product.subscriptionContracts &&
      props.subscriptionContract?.upsellMessage
    : ''

  const selectedContract = props.product.subscriptionContracts?.find(
    (contract) => contract.id === selectedFrequencyId,
  )

  const subscriptionInformationDuration = () => {
    const isSubscription = props.product.isSubscription
    const subscriptionFrequencyUnit = selectedContract?.frequencyDuration.unit
    const subscriptionFrequencyDuration =
      selectedContract?.frequencyDuration.duration?.toString().toLowerCase() ||
      ''
    const subscriptionTerm = props.product.subscriptionTerm
    const subscriptionDuration = isSubscription
      ? subscriptionTerm
      : subscriptionFrequencyDuration

    if (subscriptionDuration && subscriptionFrequencyUnit) {
      switch (subscriptionFrequencyUnit) {
        case SubscriptionFrequencyUnit.Week:
          return subscriptionDuration === 1
            ? i18nText.subscriptionDurationSingularWeeks
            : i18nText.subscriptionDurationMultipleWeeks(
                subscriptionDuration.toString(),
              )
        case SubscriptionFrequencyUnit.Month:
          return subscriptionDuration === 1
            ? i18nText.subscriptionDurationSingularMonths
            : i18nText.subscriptionDurationMultipleMonths(
                subscriptionDuration.toString(),
              )
        case SubscriptionFrequencyUnit.Year:
          return subscriptionDuration === 1
            ? i18nText.subscriptionDurationSingularYears
            : i18nText.subscriptionDurationMultipleYears(
                subscriptionDuration.toString(),
              )
        default:
          return
      }
    }
    return
  }

  return (
    <React.Fragment>
      <Container
        discount={hasDiscounts}
        promomessage={promotionalMessage}
        wishlist={showWishlist}
        dropdown={
          !!(showDropDownOrSubscriptionInfoBox && props.subscriptionContract)
        }
        limit={maxProductQuantityReached}
        clickandcollect={clickAndCollectEnabled}
        itemerrors={showItemErrors}
        outofstockerror={!props.product.inStock}
        supersize={!!props.product.supersize}
        subscriptionInfoMessage={
          !!(
            isSubscriptionEnabled &&
            (props.subscriptionContract || props.product.isSubscription)
          )
        }
        components={theme?.pageTheme?.table?.components}
        gridRowGapSpacing={theme.pageTheme?.table?.gridRowGapSpacing || 2}
      >
        {props.freeGift ? (
          <FreeGift
            title={props.product.title}
            imageUrls={props.product?.product?.basketImage}
            personalisation={{
              disclaimerMessage: i18nText.disclaimerMessage,
              personalisationValues: props.personalisationValues,
            }}
          />
        ) : (
          <ProductLink
            url={props.product.product?.url}
            title={props.product.title}
            imageUrls={props.product.product?.basketImage}
            personalisation={{
              disclaimerMessage: i18nText.disclaimerMessage,
              personalisationValues: props.personalisationValues,
            }}
          />
        )}
        {discountMessages.length > 0 && (
          <Discount data-testid="discount-container">
            {discountMessages}
          </Discount>
        )}
        {props.product.supersize && (
          <SupersizeContainer>
            <SupersizeButton
              onClick={() => {
                supersizeProduct({
                  variables: {
                    basketId,
                    settings: sessionSettings,
                    itemId: props.id,
                  },
                })
              }}
              aria-label={i18nText.supersizeButton}
              data-testid="supersize-product"
              emphasis="medium"
            >
              {i18nText.supersizeButton}
            </SupersizeButton>
            <SupersizeMessage>{supersizeMessage}</SupersizeMessage>
          </SupersizeContainer>
        )}
        {isSubscriptionEnabled && props.subscriptionContract && (
          <React.Fragment>
            {promotionalMessage && (
              <PromotionProductMessage content={promotionalMessage} />
            )}
            <SubscriptionContainer>
              <SubscriptionFrequencyDropDown
                availableFrequencies={props.product.availableFrequencies}
                title={i18nText.dropDownTitle}
                updateSubscriptionCallback={updateSubscriptionCallback}
                contractValue={selectedFrequencyId}
                onChangeValue={updateSelectedFrequencyId}
                width="100%"
              />
            </SubscriptionContainer>
          </React.Fragment>
        )}
        {showWishlist && (
          <WishlistContainer
            buttonType={WishlistButtonType.TEXT}
            i18nText={wishlistI18nText}
            sku={props.product.sku}
            title={props.product.title}
            price={props.standardPricePerUnit?.amount}
            inWishlist={props.product.inWishlist || false}
            brandName={props.product?.product?.brand?.name}
            itemCategories={props.product?.content}
            externalIdentifier={props.product?.product?.externalIdentifier}
            variantIdentifier={props.product?.variantIdentifier}
            index={props.index}
          />
        )}
        <UnitPriceContainer
          freeGift={props.freeGift}
          price={props.product.price}
          standardPricePerUnit={props.standardPricePerUnit}
          hasRrpPrice={hasRrpPrice}
          themeComponents={theme.pageTheme?.table?.components}
          fontStyle={theme.pageTheme?.table?.font.style}
          unitPriceTheme={theme.pageTheme?.table?.unitPrice}
        />
        {(theme?.pageTheme?.table?.components?.sm?.order?.includes(
          'quantity',
        ) ??
          true) && (
          <QuantitySelectorContainer>
            {!props.freeGift ? (
              <QuantitySelector {...quantitySelectorProps} />
            ) : (
              <QuantityPlaceholder>{props.quantity}</QuantityPlaceholder>
            )}
          </QuantitySelectorContainer>
        )}
        {maxProductQuantityReached && (
          <MaxOrderMessage
            data-testid="max-order-container"
            type="info"
            text={i18nText.maxOrderText}
            hideIconMobile={true}
            renderAnnouncer={renderAnnouncer}
          />
        )}
        <TotalPriceContainer
          freeGift={props.freeGift}
          displayBasketWithStandardPrice={displayBasketWithStandardPrice}
          totalChargePrice={props.totalChargePrice}
          totalStandardPrice={props.totalStandardPrice}
          i18nFreeText={i18nText.free}
          i18nSubtotalLabel={i18nText.subtotalLabel}
          showSubtotalLabelonMobile={
            theme.pageTheme?.table?.showSubtotalLabelonMobile
          }
          themeComponents={theme.pageTheme?.table?.components}
          fontStyle={theme.pageTheme?.table?.font.style}
        />
        <RemoveBasketItem
          onClick={() => {
            removeItem({
              variables: {
                basketId,
                settings: sessionSettings,
                itemId: props.id,
              },
            })
            removePressed.current = true
          }}
          freeGift={props.freeGift}
          removeItemLabel={i18nText.removeItemLabel}
          icon={theme?.pageTheme?.table?.icon}
        />
        {clickAndCollectEnabled && (
          <React.Fragment>
            <ClickAndCollect>
              {props.messages && props.fulfilmentMethod && (
                <React.Fragment>
                  <Message
                    messages={props.messages}
                    fulfilmentMethod={props.fulfilmentMethod!}
                  />
                  <FulfilmentMethodsMessage
                    messages={props.messages}
                    presentClickAndCollectModalProps={{
                      sku: props.product.sku,
                      image: props.product.product?.images?.[0]?.largeProduct,
                      title: props.product.title,
                      isProductPage: false,
                      quantity: props.quantity,
                    }}
                    itemId={props.id}
                    storeName={props.store?.displayName}
                    storeStock={props.storeStock || undefined}
                    fulfilmentMethod={props.fulfilmentMethod}
                    availableFulfilmentMethods={
                      props.product.eligibleForFulfilmentMethods
                    }
                    i18nText={fulfilmentMethodsI18nText}
                    leadTime={props.product.leadTime}
                    inStock={props.product.inStock}
                    inStockLocations={props.product.inStockLocations}
                    isCheckStock={props.product.isCheckStock}
                    isOrderInStore={props.product.isOrderInStore}
                    isBookable={props.product.isBookable}
                    weightGroups={props.product.weightGroups}
                  />
                </React.Fragment>
              )}
            </ClickAndCollect>
          </React.Fragment>
        )}
        {showBasketItemOutOfStockMessage && !props.product.inStock && (
          <div
            style={{
              gridArea: 'outofstockerror',
              justifySelf: 'flex-start',
              alignSelf: 'flex-start',
            }}
            data-testid="out-of-stock-error"
          >
            <PlatformMessage
              type="error"
              text={i18nText.productOutOfStockError}
            />
          </div>
        )}
        {isSubscriptionEnabled &&
          (props.subscriptionContract || props.product.isSubscription) && (
            <SubscriptionInfoMessageContainer>
              <SubscriptionInfoBox
                title={{
                  text: i18nText.subscriptionInformationTitle,
                }}
                subtitle={
                  <Subtitle>
                    {props.product.isSubscription
                      ? props.chargePricePerUnit.displayValue
                      : i18nText.subscriptionDiscountPercentage(
                          `${selectedContract?.initialDiscountPercentage}%`,
                        )}
                    <SvgAccountSubscriptionsOutline />
                    {subscriptionInformationDuration()}
                  </Subtitle>
                }
                subsText={
                  props.product.isSubscription
                    ? i18nText.subscriptionInformationNote2
                    : i18nText.subscriptionInformationNote1
                }
                additionalStyle={{ marginTop: 2, padding: 2 }}
              />
            </SubscriptionInfoMessageContainer>
          )}
      </Container>
    </React.Fragment>
  )
}

const UnitPriceContainer = (props: {
  freeGift: boolean
  price?: BasketData['items'][0]['product']['price']
  standardPricePerUnit?: BasketData['items'][0]['standardPricePerUnit']
  hasRrpPrice?: boolean
  themeComponents?: TableComponents
  fontStyle: TextStyle
  unitPriceTheme?: UnitPriceTheme
}) =>
  props.themeComponents?.sm?.order?.includes('price') ?? true ? (
    <UnitPrice
      data-testid="initial-price"
      fontStyle={props.fontStyle}
      textColor={
        props.unitPriceTheme?.showRrpPrice && props.hasRrpPrice
          ? props.unitPriceTheme?.priceWithRRP?.textStyling?.textColor
          : props.unitPriceTheme?.price?.textStyling?.textColor
      }
      showOnMobile={props.unitPriceTheme?.showOnMobile}
    >
      {props.unitPriceTheme?.showRrpPrice && props.hasRrpPrice && (
        <RrpPrice textStyling={props.unitPriceTheme?.rrp?.textStyling}>
          {!props.freeGift && props.price?.rrp.displayValue}
        </RrpPrice>
      )}
      {!props.freeGift && props.standardPricePerUnit?.displayValue}
    </UnitPrice>
  ) : null

const TotalPriceContainer = (props: {
  showSubtotalLabelonMobile?: boolean
  themeComponents?: TableComponents
  fontStyle: TextStyle
  freeGift: boolean
  displayBasketWithStandardPrice?: boolean
  totalStandardPrice: BasketData['items'][0]['totalStandardPrice']
  totalChargePrice: BasketData['items'][0]['totalChargePrice']
  i18nFreeText: string
  i18nSubtotalLabel: string
}) =>
  props.themeComponents?.sm?.order?.includes('subtotal') ?? true ? (
    <TotalPrice fontStyle={props.fontStyle}>
      {!props.freeGift && props.showSubtotalLabelonMobile && (
        <TotalPriceLabel>{`${props.i18nSubtotalLabel}: `}</TotalPriceLabel>
      )}
      {!props.freeGift
        ? props.displayBasketWithStandardPrice
          ? props.totalStandardPrice.displayValue
          : props.totalChargePrice.displayValue
        : props.i18nFreeText}
    </TotalPrice>
  ) : null
