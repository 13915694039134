import * as React from 'react'
import { Helmet } from 'react-helmet'

export const AmpFacebook = (props) => {
  const { className } = props
  return (
    <React.Fragment>
      <Helmet key="helmet">
        <script
          async
          custom-element="amp-facebook"
          src="https://cdn.ampproject.org/v0/amp-facebook-0.1.js"
        />
      </Helmet>
      <amp-facebook className={className} {...props}></amp-facebook>
    </React.Fragment>
  )
}
