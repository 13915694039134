import * as React from 'react'
import Head from 'react-helmet'

import { AmpTwitter } from '@thg-commerce/gravity-amp'

import { GenericEmbedProps } from './types'
import { EmbedWrapper } from './style'

export const Twitter = (props: GenericEmbedProps) => {
  if (props.isAmp) {
    const regex = 'twitter.com/.*/status(?:es)?/([^/?]+)'
    const match = props.url.match(regex)

    return (
      <AmpTwitter
        width={props.width || '375'}
        height={props.height || '472'}
        layout="responsive"
        data-tweetid={(match && match[1]) || ''}
      />
    )
  }

  return (
    <React.Fragment>
      <Head>
        <script
          key="twitter-embed"
          id="twitter-embed"
          async
          src="https://platform.twitter.com/widgets.js"
        ></script>
      </Head>
      <EmbedWrapper>
        <blockquote
          // className required for widget.js
          className="twitter-tweet"
          style={{ overflow: 'hidden', maxWidth: '100%' }}
        >
          <a href={props.url}>{props.url}</a>
        </blockquote>
      </EmbedWrapper>
    </React.Fragment>
  )
}
