import * as React from 'react'
import Head from 'react-helmet'

import { AmpInstagram } from '@thg-commerce/gravity-amp'

import { GenericEmbedProps } from './types'
import { AmpEmbedWrapper, EmbedWrapper } from './style'

export const Instagram = (props: GenericEmbedProps) => {
  if (props.isAmp) {
    const regex = /(?:https?:\/\/www\.)?instagram\.com\S*?\/p\/(\w{11})\/?/
    const match = props.url.match(regex)

    return (
      <AmpEmbedWrapper>
        <AmpInstagram
          width="1"
          height="1"
          layout="responsive"
          data-shortcode={(match && match[1]) || ''}
        />
      </AmpEmbedWrapper>
    )
  }

  return (
    <React.Fragment>
      <Head>
        <script
          src="//www.instagram.com/embed.js"
          key="instagram-embed"
          id="instagram-embed"
          async
        ></script>
      </Head>
      <EmbedWrapper>
        <blockquote
          className="instagram-media"
          data-instgrm-permalink={props.url}
          data-instgrm-version="13"
          style={{
            backgroundColor: '#FFF',
            border: 0,
            borderRadius: '3px',
            boxShadow:
              '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
            margin: '1px',
            padding: 0,
            maxWidth: '540px',
            minWidth: '326px',
            width: '100%',
          }}
        ></blockquote>
      </EmbedWrapper>
    </React.Fragment>
  )
}
