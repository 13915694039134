import * as React from 'react'

export const AmpSidebar = (props) => {
  const { children, className } = props
  return (
    <React.Fragment>
      <amp-sidebar className={className} {...props} key="sidebar">
        {children}
      </amp-sidebar>
    </React.Fragment>
  )
}

export default AmpSidebar
