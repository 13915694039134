import * as React from 'react'
import { Helmet } from 'react-helmet'

export const AmpTwitter = (props) => {
  const { className } = props
  return (
    <React.Fragment>
      <Helmet key="helmet">
        <script
          async
          custom-element="amp-twitter"
          src="https://cdn.ampproject.org/v0/amp-twitter-0.1.js"
        />
      </Helmet>
      <amp-twitter className={className} {...props}></amp-twitter>
    </React.Fragment>
  )
}
