import * as React from 'react'
import Head from 'react-helmet'

import { AmpPinterest } from '@thg-commerce/gravity-amp'

import { GenericEmbedProps } from './types'
import { AmpEmbedWrapper, EmbedWrapper } from './style'

export const Pinterest = (props: GenericEmbedProps) => {
  if (props.isAmp) {
    return (
      <AmpEmbedWrapper>
        <AmpPinterest
          width={props.width || '236'}
          height={props.height || '326'}
          data-do="embedPin"
          data-url={props.url}
        />
      </AmpEmbedWrapper>
    )
  }

  return (
    <React.Fragment>
      <Head>
        <script
          src="//assets.pinterest.com/js/pinit.js"
          key="interest-embed"
          id="pinterest-embed"
          async
          defer
        ></script>
      </Head>
      <EmbedWrapper>
        <a
          href={props.url}
          data-pin-do="embedPin"
          style={{ overflow: 'hidden', maxWidth: '100%' }}
        >
          {props.url}
        </a>
      </EmbedWrapper>
    </React.Fragment>
  )
}
